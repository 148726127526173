<template>
  <div id="app" class="pos-r contain-main">

    <div class="pos-g" :class="dark_mode ? 'bg-white' : 'bg-blue-ice'"></div>
    <div class="pos-r contain-main compensate-footer">

      <header_default />

      <div class="minhview">
        <transition
          appear
          :css="false"
          mode="out-in"
          @appear="appear"
          @enter="enter"
          @leave="leave"
        >

          <router-view :key="$route.fullPath" /> 
          <!-- 
          <router-view />
          -->

        </transition> 
      </div>

      <footer_default />

    </div>

    <cookie_snack :cookie_show="cookie_show" />
    <preload />
    <sneekpreview v-if="preview_show" />

    <div class="pos-g bg-white hidden opacity-0" id="hider_main"></div>
  </div>
</template>


<script>
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import control_main from '@/modules/control_main'

import gsap from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
const scrollPlugin = ScrollToPlugin
gsap.registerPlugin(ScrollToPlugin)


import preload from '@/components/preload.vue'
import sneekpreview from '@/components/sneekpreview.vue'

import header_default from '@/components/ui/header_default.vue'
import footer_default from '@/components/ui/footer_default.vue'
import cookie_snack from '@/components/cookie_snack.vue'

var lv = {}
lv.thisVue = null

export default {
  name: "App",
  components: {
    preload,
    header_default,
    footer_default,
    cookie_snack,
    sneekpreview
  },
  data() {
    return {
      dark_mode: false,
      cookie_show: false,
      preview_show: false,
      dark_hider: false,
      duration_in: 0.3
    }
  },
  mounted () {

    lv.thisVue = this

    if(!globals.cookie_allowed){
      this.cookie_show = true
    }
    EventBus.$on(lv.dataEvent, (e) => {
      this.cookie_show = false
    })

    if(globals.preview_allowed){
      control_main.preloader_done()
    }else{
      this.preview_show = true
    }

    this.$nextTick(function () {
      if(globals.dark_section){
        lv.thisVue.dark_mode = true
      }
    })
    EventBus.$on('site_section_dark', (e) => {
      lv.thisVue.dark_mode = true
    })
    EventBus.$on('site_section_light', (e) => {
      lv.thisVue.dark_mode = false
    })

    // hijack a href to router
    window.addEventListener('click', event => {
      let { target } = event
      while (target && target.tagName !== 'A') target = target.parentNode
      if (target && target.matches("a:not([href*='://'])") && target.href) {
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
        if (metaKey || altKey || ctrlKey || shiftKey) return
        if (defaultPrevented) return
        if (button !== undefined && button !== 0) return
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute('target')
          if (/\b_blank\b/i.test(linkTarget)) return
        }
        const url = new URL(target.href)
        const to = url.pathname
        if (window.location.pathname !== to && event.preventDefault) {
          event.preventDefault()
          this.$router.push(to)
        }
      }
    })
  },
  methods: {
    appear(el, done) {
      //this.checkSection()
      gsap.to('#hider_main',{duration:0.3, display: 'none', opacity: 0, ease:'power3.inOut', onComplete: done })
      gsap.delayedCall(0.3, this.checkDark)
    },
    enter(el, done) {
      //this.checkSection()
      gsap.to('#hider_main',{duration: this.duration_in, display: 'none', opacity: 0, ease:'power3.inOut', onComplete: done })
      gsap.delayedCall(0.3, this.checkDark)
    },
    leave(el, done) {
      this.checkSection()
      /*
      if(window.pageYOffset > 117){
        gsap.set('#hider_main',{'z-index': 100})
      }else{
        gsap.set('#hider_main',{'z-index': 'auto'})
      }
      */
      gsap.to('#hider_main',{duration:0.3, display: 'block', opacity: 1, ease:'power3.inOut', onComplete: done })
      gsap.delayedCall(0.3, this.checkDark)
      gsap.to(window, {delay:0.3, duration: 0.2, scrollTo: {y: 0}, ease:'none'})
      /*
      if(!globals.scrollSaved.y >= 0){
        control_main.console_log(globals.scrollSaved)
      }
      */
    },
    checkSection(){
      if(this.$route.name == 'Lesson'){
        this.duration_in = 0.9
      }else{
        this.duration_in = 0.3
      }
      if(this.$route.name == 'Course' || this.$route.name == 'Lesson'){
        if(!this.dark_hider){
          document.querySelector('#hider_main').setAttribute('style', 'background:#000;')
          this.dark_hider = true
        }
      }else{
        if(this.dark_hider){
          document.querySelector('#hider_main').setAttribute('style', 'background:#fff;')
          this.dark_hider = false
        }
      }
    },
    checkDark(){
      var body_node = document.getElementsByTagName('BODY')[0]
      const routes_have_dark = ['Profile','Courses','Course','Lesson','Preview','PreviewCourses','Takeaways']
      if(routes_have_dark.includes(this.$route.name) && !globals.dark_section){
        globals.dark_section = true
        body_node.classList.add('dark')
        EventBus.$emit('site_section_dark')
      }
      if(!routes_have_dark.includes(this.$route.name) && globals.dark_section){
        globals.dark_section = false
        body_node.classList.remove('dark')
        EventBus.$emit('site_section_light')
      }

    }
  }
}


</script>

<style scoped lang="scss">

.contain-main{
  min-height: 100vh;
}
.compensate-footer{
  padding-bottom: 191px;
}

.minhview{
  min-height: 500px;
}

@media (max-width: 480px){
  .compensate-footer{
    padding-bottom: 283px;
  }
}


</style>
