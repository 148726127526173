<template>
  <div class="avatar_wrap" aria-hidden="true">
    <img v-if="src" :src="src" :style="{ width: width + 'px' }" />
    <img class="icon_avat" src="@/assets/img/icons_graphic/icon_user.png" v-if="!src && size === 1" />
    <img class="icon_avat" src="@/assets/img/icons_graphic/100x100/icon_user.png" v-if="!src && size === 2" />
  </div>
</template>

<script>
export default {
  name: 'avatar',
  props: ['size','src'],
  data () {
    return {
      width: 60
    }
  },
  mounted () {
    if (this.$props.size === 2) {
      this.width = 100
    }
  },
  computed: {},
  methods: {}
}
</script>
<style scoped>

</style>