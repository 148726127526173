<template>

  <div class="bg-lemon">
    <div class="mode-center-big">
      <div class="text-hdr-4 pad-cta-1 text-center">
        <span>
          {{cta_data.text}}
        </span>
        <div class="v-space-cta-inbetween"></div>

        <btn_page :to="cta_data.button.link" :text="cta_data.button.text" />
        <div class="a"></div>

      </div>
    </div>
  </div>

</template>

<script>
import btn_page from '@/components/ui/btn_page.vue'

var lv = {}
lv.thisVue = null

export default {
  name: 'hero_cta',
  components: {
    btn_page
  },
  props: ['cta_data'],
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  }
}

</script>

<style lang="scss" scoped>


</style>
