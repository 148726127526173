<template>
  <div class="fixed bottom-0 right-0 p-6 z-50" v-if="cookie_show">
    <div class="rounded bg-white shadow-lg px-6 py-4 overflow-hidden origin-bottom-right" id="cookie_notice">

      <div class="flex flex-row items-center" id="cookie_notice_content">
        <span class=" text-gray-300 mr-3 mxxsm:hidden">
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path></svg>
        </span>
        <span class="mr-4">
          {{cookie_text}}
        </span>

        <a href="#wachtwoord-wijzigen" @click.prevent="cookieAllow" class="text-purple-700 px-2 py-1 rounded-md hover:bg-purple-600 hover:text-white" id="cookie_notice_button" >Akkoord</a>
      </div>


    </div>
  </div>
</template>

<script>
import globals from "@/modules/globals"
import { EventBus } from "@/modules/event_bus"
import api from "@/modules/api"
import gsap from 'gsap'

var lv = {}
lv.thisVue = null

export default {
  name: "cookie_snack",
  props: ['cookie_show'],
  data() {
    return {
      cookie_text: 'We gebruiken cookies, uitsluitend om deze website goed te laten functioneren.',
      cookie_timeout: -1,
      cookie_button_text: 'Akkoord'
    }
  },
  mounted() {
    lv.thisVue = this

  },
  methods: {
    cookieAllow() {
      document.querySelector('#cookie_notice_button').setAttribute('style', 'cursor:default;')
      gsap.to('#cookie_notice_button',{duration:0.2, scale:1.2, ease:'power3.inOut'})
      gsap.to('#cookie_notice_button',{delay: 0.2, duration:0.2, scale:1, ease:'power3.inOut'})
      gsap.to('#cookie_notice_content',{delay: 0.4, duration:0.3, opacity:0})
      gsap.to('#cookie_notice',{delay: 0.5, duration:0.4, 'scaleX':0, ease:'power3.inOut', onComplete:function(){
        sendCookieAllow()
      }})
    }
  }
}

lv.dataTask = "cookie_set"
lv.dataEvent = "data_cookie_set"
function sendCookieAllow(){
  api.call({
    task: lv.dataTask,
    event: lv.dataEvent,
    global: false
  })
}

</script>

<style scoped lang="scss">

</style>
