<template>

  <router-link
  :to="to" 
  v-bind:class="classes"
  >
    {{text}}
  </router-link>

</template>


<script>
import { EventBus } from '@/modules/event_bus'

export default {
  name: 'btn_nav',
  props: ['type','text','to','behave'],
  data() {
    return {
      classes: '',
      classes_text: 'font-mono nav-text text-black hover:text-blue-sky focus:text-blue-sky py-2 focus:outline-none transition duration-300 ease-in-out',
      classes_text_dark: 'font-mono nav-text text-gray-600 hover:text-black focus:text-black py-2 focus:outline-none transition duration-300 ease-in-out',
      classes_block: 'font-mono nav-text text-white bg-blue-sky hover:bg-blue-sky-600 focus:bg-blue-sky nav-btn-round py-2 focus:outline-none transition duration-300 ease-in-out uppercase',
      classes_mobile: 'font-mono nav-text mt-1 block px-3 py-2 rounded-md text-black hover:text-blue-sky hover:bg-grey-min focus:outline-none focus:text-blue-sky focus:bg-gray-100 transition duration-300 ease-in-out',
      classes_mobile_block: 'font-mono nav-text mt-1 block px-3 py-2 rounded-md text-white bg-blue-sky hover:bg-blue-sky-600 focus:outline-none focus:text-blue-sky focus:bg-gray-100 transition duration-300 ease-in-out',
      classes_dropdown: 'block px-6 py-3 leading-5 text-gray-700 hover:bg-gray-100 hover:text-blue-sky-600 focus:outline-none focus:bg-gray-100 transition duration-300 ease-in-out',
    }
  },
  mounted() {
    if(this.$props.dark_mode){
      this.classes_text = this.classes_text_dark
    }
    this.classes = this['classes_' + this.$props.type]
    if(this.$props.type == 'mobile' && this.$props.behave == 'block'){
      this.classes = this.classes_mobile_block
    }

    EventBus.$on('site_section_dark', (e) => {
      if(this.$props.type == 'text'){
        this.classes = this.classes_text_dark
      }
    })
    EventBus.$on('site_section_light', (e) => {
      if(this.$props.type == 'text'){
        this.classes = this.classes_text
      }
    })
  }
}
</script>

<style lang="scss" scoped>

  .nav-btn-round{
    padding-left: 38px;
    padding-right: 38px;
    border-radius: 5px;
    letter-spacing: 1px;
  }

</style>
