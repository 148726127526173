
import * as cheet from 'cheet.js'
import { EventBus } from './event_bus'
import globals from './globals'
import api from './api'


var lv = {}

let control_main = {

    connect_first(){
        lv.task = 'first_contact'
        lv.event = 'data_first_contact'
        api.call({
            task: lv.task,
            event: lv.event,
            global: false
        })
        EventBus.$on(lv.event, (e) => {
            control_main.connect_first_done(e)
        })
    },
    connect_first_done(d){
        if(d.result == 'error'){
            control_main.console_log('sorry, no connection')
            return
        }
        if(d.data.data.result_login == 'not_loggedin'){
            control_main.console_log('connect_first: not logged in')
        }
        if(d.result == 'ok'){
            if(d.data.data.result_login == 'loggedin'){
                control_main.console_log('connect_first: logged in')
                globals.logged_in = true
                globals.user_data = {
                    id: d.data.data.user_data.id,
                    first_name: d.data.data.user_data.first_name,
                    last_name: d.data.data.user_data.last_name,
                    motivation: d.data.data.user_data.motivation
                }
                if(d.data.data.user_data.avatar){
                    globals.user_data.avatar = globals.host + globals.path_avatars + d.data.data.user_data.avatar + '.png'
                }
            }
            if(d.data.data.result_cookie == 'cookie_not_found'){
                control_main.console_log('cookie needs to be allowed')
            }
            if(d.data.data.result_cookie == 'cookie_found'){
                control_main.console_log('cookie is allowed')
                globals.cookie_allowed = true
            }
            if(d.data.data.result_preview == 'cookie_found'){
                control_main.console_log('preview is allowed')
                globals.preview_allowed = true
            }
            if(d.data.data.items_textpages){
                globals.api_data['textpages_fetch'] = d.data.data.items_textpages
            }
        }
        globals.connection_established = true
        EventBus.$emit('connect_first_success')
    },
    login_check(){
        lv.loginCheckTask = 'login_check'
        lv.loginCheckEvent = 'data_login_check'
        api.call({
            task: lv.loginCheckTask,
            event: lv.loginCheckEvent,
            global: false
        })
        EventBus.$on(lv.loginCheckEvent, (e) => {
            control_main.login_check_done(e)
        })
    },
    login_check_done(d){
        if(d.result == 'nok'){
            control_main.console_log('login_check: not logged in')
        }
        if(d.result == 'ok'){
            if(d.data.respond == 'loggedin'){
                control_main.console_log('login_check: logged in')
                globals.logged_in = true
                globals.user_data = {
                    id: d.data.user_data.id,
                    first_name: d.data.user_data.first_name,
                    last_name: d.data.user_data.last_name,
                    motivation: d.data.user_data.motivation
                }
                if(d.data.user_data.avatar){
                    globals.user_data.avatar = globals.host + globals.path_avatars + d.data.user_data.avatar + '.png'
                }
            }
        }
        globals.connection_established = true
        EventBus.$emit('login_check_success')
    },
    log_out(){
        lv.logoutCheckTask = 'logout'
        lv.logoutCheckEvent = 'data_logout'
        api.call({
            task: lv.logoutCheckTask,
            event: lv.logoutCheckEvent,
            global: false
        })
        EventBus.$on(lv.logoutCheckEvent, (e) => {
            control_main.log_out_done(e);
        })
    },
    log_out_done(d){
        if(d.result == 'nok'){
            control_main.console_log('not logged out')
            return
        }
        control_main.console_log('logged out')
        globals.logged_in = false
        globals.user_data = {
            id: 0,
            first_name: '',
            last_name: '',
            motivation: '',
            avatar: null
        }
        EventBus.$emit('logged_out')
    },
    cookie_check(){
        lv.loginCheckTask = 'cookie_check'
        lv.loginCheckEvent = 'data_cookie_check'
        api.call({
            task: lv.loginCheckTask,
            event: lv.loginCheckEvent,
            global: false
        })
        EventBus.$on(lv.loginCheckEvent, (e) => {
            control_main.cookie_check_done(e)
        })
    },
    cookie_check_done(d){
        if(d.result == 'nok'){
            control_main.console_log('cookie needs to be allowed')
        }
        if(d.result == 'ok'){
            control_main.console_log('cookie already allowed')
            globals.cookie_allowed = true
        }
        EventBus.$emit('cookie_check_success')
    },
    cookie_set(){
        lv.loginCheckTask = 'cookie_set'
        lv.loginCheckEvent = 'data_cookie_set'
        api.call({
            task: lv.loginCheckTask,
            event: lv.loginCheckEvent,
            global: false
        })
        EventBus.$on(lv.loginCheckEvent, (e) => {
            control_main.cookie_set_done(e)
        })
    },
    cookie_set_done(d){
        if(d.result == 'nok'){
            control_main.console_log('cookie needs to be allowed')
        }
        if(d.result == 'ok'){
            control_main.console_log('cookie is allowed')
            globals.cookie_allowed = true
        }
        EventBus.$emit('cookie_set_success')
    },
    preloader_done(){
        EventBus.$emit('preloader_done')
    },
    console_log(msg){
        lv.golog = false
        if(process.env.NODE_ENV !== 'production'){
            lv.golog = true
        }
        if(globals.consolify){
            lv.golog = true
        }
        if(lv.golog){
            console.log(msg)
        }
    }
}


cheet('g l o b a l s', function () {
    console.log(globals)
 })
 

 cheet('c o n s o l i f y', function () {
    if(localStorage.getItem('consolify')){
        console.log('got consolify, removing it')
        globals.consolify = false
        localStorage.removeItem('consolify')
    }else{
        console.log('havent got consolify, setting it')
        globals.consolify = true
        localStorage.setItem('consolify', true)
    }
 })
 
  
export default control_main
