
import { EventBus } from './event_bus'
import { globals } from './globals'
import control_main from '@/modules/control_main'
import * as cheet from 'cheet.js'
import axios from 'axios'
import sha256 from 'crypto-js/sha256'

var lv = {}
lv.keysource = 'd8heyrgftyr6775ytugh5y4h8u5j994e5e4d5f4t55njf984yg4h55y4hu5j4i8632k1i878uoooplw2841'
lv.url_base = globals.host + 'api/'
lv.url_api = lv.url_base + '_api.php'
lv.url_avatar = lv.url_base + '_avatar.php'

lv.url_admin_user = globals.host + 'learning-platform/users/me'

lv.slug_tasks = ['course_fetch', 'lesson_fetch']

let api = {

   makeKey(str) {
      lv.keysplit = lv.keysource.split('55')
      lv.sha = sha256(lv.keysplit[1] + str).toString()
   },
   callData(props) {
      lv.data = props
      lv.data_string = JSON.stringify(lv.data)
      lv.key = api.makeKey(lv.data_string)

      axios.post(lv.url_api, {
         task: props.task,
         data: JSON.stringify(lv.data),
         key: lv.sha
      }, { withCredentials: true })
      .then(function (response) {
         control_main.console_log('---- api ---- ' + props.task)
         control_main.console_log(response.data)

         if (response.data.result == 'ok') {
            lv.obj = props.task
            lv.adata = response.data.data.data
            if (props.task == 'meta') {
               lv.adata = response.data.data.data.meta
            }
            if (lv.slug_tasks.includes(props.task)) {
               lv.obj = props.task + '_' + props.slug
            }
            if (props.global) {
               api.setGlobal({ obj: lv.obj, data: lv.adata })
            }

            if(props.task == 'answer_save'){
               globals.api_data['lesson_fetch_' + props.slug].item.percentage = response.data.data.percentages.lesson.percentage
               if(globals.api_data['course_fetch_' + response.data.data.percentages.course.slug]){
                  globals.api_data['course_fetch_' + response.data.data.percentages.course.slug].item.percentage = response.data.data.percentages.course.percentage
                  globals.api_data['course_fetch_' + response.data.data.percentages.course.slug].lessons[response.data.data.percentages.lesson.slug].percentage = response.data.data.percentages.lesson.percentage
               }
               if(globals.api_data['courses_fetch']){
                  globals.api_data['courses_fetch'].items[response.data.data.percentages.course.slug].percentage = response.data.data.percentages.course.percentage
               }
               control_main.console_log(props.answerData)
               lv.info = {
                  lesson_id: props.answerData.lesson_id,
                  course_id: props.answerData.course_id,
                  percentages: {
                     course: response.data.data.percentages.course.percentage,
                     lesson: response.data.data.percentages.lesson.percentage
                  }
               }
               EventBus.$emit('percentage_change_lesson', lv.info)
            }

         }

         EventBus.$emit(props.event, response.data)
      })
      .catch(function (error) {
         lv.error = {
            result: 'error',
            message: 'Something went wrong... try again later. Sorry!',
            error: error
         }
         //control_main.console_log(error)
         EventBus.$emit(props.event, lv.error)
      })
   },
   call(props) {

      lv.task = props.task
      if (lv.slug_tasks.includes(props.task)) {
         lv.task = props.task + '_' + props.slug
      }

      if (globals.api_data[lv.task] && props.global != false) {
         control_main.console_log('---- fetch from globals ---- ' + lv.task )
         lv.res = {
            result: 'ok',
            data: {
               respond: 'allready fetched'
            }
         }
         EventBus.$emit(props.event, lv.res)
         return
      }

      //control_main.console_log('---- new api result ----')
      api.callData(props)
   },
   setGlobal(d) {
      globals.api_data[d.obj] = d.data
   },
   avatarUpload(f) {
      lv.formData = new FormData()
      lv.formData.append('file', f)
      axios
         .post(lv.url_avatar, lv.formData, {
            withCredentials: true,
            headers: {
               'Content-Type': 'multipart/form-data',
            }
         })
         .then(function (response) {
            control_main.console_log('---- api avatar ----')
            //control_main.console_log(response.data)
            if (response.data.result == 'ok') {
               if (response.data.data.respond == 'upload_success') {
                  lv.avatar = globals.host + globals.path_avatars + response.data.data.avatar + '.png'
                  lv.res = {
                     result: 'ok',
                     avatar: lv.avatar
                  }
                  EventBus.$emit('avatar_uploaded', lv.res)
               }
            }
         })
         .catch(function (error) {
            control_main.console_log(error)
            lv.res = {
               result: 'nok',
               error: error
            }
            EventBus.$emit('avatar_uploaded', lv.res)
         })
   },
   getAdminUser(){
      control_main.console_log('getAdminUser')
      if(process.env.NODE_ENV !== 'production'){
         control_main.console_log('development, use cheat to get admin user')
         return
      }
      axios.get(lv.url_admin_user, {
      }, { withCredentials: true })
      .then(function (response) {
         control_main.console_log('checkingAdminUser')
         control_main.console_log(response)
         globals.admin_user = null
         if(response){
            if(response.data){
               if(response.data.data){
                  if(response.data.data.status == 'active'){
                     control_main.console_log('haveAdminUser')
                     globals.admin_user = response.data.data
                  }
               }
            }
         }
         EventBus.$emit('fetched_admin_user', response)
      })
      .catch(function (error) {
         control_main.console_log('haveNoAdminUser')
         globals.admin_user = null
         EventBus.$emit('fetched_admin_user')
      })
   }
}

EventBus.$on('fetch_meta', (e) => {
   api.call(lv.keepProps)
})

export default api
