<template>

<nav class="relative z-10 navtrans" :class="dark_mode ? 'bg-blue-ice' : 'bg-white'">
  <div class="mode-center">
    <div class="relative flex items-center justify-between nav-height">
      <div class="absolute inset-y-0 left-0 flex items-center">
        <div class="block mnxl:hidden">
          <button class="inline-flex items-center justify-center p-1 text-black bg-blue-ice rounded-lg
            focus:outline-none focus:text-blue-ice focus:bg-black transition duration-150 ease-in-out" aria-label="Main menu" aria-expanded="false"
            v-on:click="mobile_menu_toggle"
          >
            <svg class="block h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" v-show="mobile_menu_visible === false" >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
            <svg class=" h-10 w-10" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"  v-show="mobile_menu_visible === true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
      <div class="flex-1 flex items-center justify-center mnxl:items-stretch mnxl:justify-start dark:opacity-10">
        <div class="relative flex-shrink-0" style="top: -3px;">   
          <!--              
          <img src="@/assets/img/logo-nav-sm.png" class="dark_hide" />
          <img src="@/assets/img/logo-nav-sm_dark.png" class="dark_show">
          -->
          <img src="@/assets/img/logo-nav-sm.png">
          <router-link to="/" class="pos-g outline-none"></router-link>
        </div>
        <div class="mxxl:hidden">
          <div class="flex nav-btns-wrap " :class="dark_mode ? 'nav-btns-wrap-dark' : ''">

              <btn_nav  
                v-for="(item, index) in items_nav_primary" 
                :key="index" 
                :type="item.type" 
                :text="item.text" 
                :to="item.to" 
              />
              
          </div>
        </div>
      </div>
      <div class="absolute inset-y-0 right-0 flex items-center mxxl:hidden">

        <div v-if="user_menu_visible === false">
          <btn_nav  
            v-for="(item, index) in items_nav_secondary" 
            :key="index" 
            :type="item.type" 
            :text="item.text" 
            :to="item.to" 
          />
        </div>

        <div class="ml-3 relative" v-if="user_menu_visible === true">
          <div>
            <button class="flex border-2 border-transparent rounded-full transform-gpu hover:scale-110 focus:outline-none focus:scale-110 transition duration-200 ease-in-out" id="user-menu"
              v-on:click="user_dropdown_toggle" v-click-outside="user_dropdown_blur"
            >
              <avatar :size="1" :src="avatar_src"></avatar>
            </button>
          </div>
          <div class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg" v-show="user_dropdown_visible">
            <div class="py-1 rounded-md bg-white shadow-xs font-bold border border-gray relative z-50">

              <btn_nav  
                v-for="(item, index) in items_nav_dropdown" 
                :key="'mdd_' + index" 
                type="dropdown" 
                :text="item.text" 
                :to="item.to" 
                :icon="item.icon"
              />
              
            </div>
          </div>
        </div>
              
      </div>
    </div>
  </div>

  <div class="mnxl:hidden" v-show="mobile_menu_visible">
    <div class="pt-2 pb-3 px-3">

      <btn_nav  
        v-for="(item, index) in items_nav_primary" 
        :key="'mp_' + index" 
        type="mobile" 
        :text="item.text" 
        :to="item.to" 
      />

      <btn_nav  
        v-show="logged_in === false"
        v-for="(item, index) in items_nav_secondary" 
        :key="'ms_' + index" 
        type="mobile"
        :behave="item.behave" 
        :text="item.text" 
        :to="item.to" 
      />
              
      <btn_nav  
        v-show="logged_in === true"
        v-for="(item, index) in items_nav_dropdown" 
        :key="'mi_' + index" 
        type="mobile"
        :behave="item.behave" 
        :text="item.text" 
        :to="item.to" 
      />
              
    </div>
  </div>

</nav>

</template>


<script>
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'

import btn_nav from './btn_nav.vue'
import avatar from '@/components/account/avatar.vue'

var lv = {}
lv.thisVue = null

export default {
  name: 'header_default',
  components:{
    btn_nav,
    avatar,
  },
  props: [],
  data() {
    return {
      dark_mode: false,
      logged_in: false,
      user_name: '',
      avatar_src: '',
      mobile_menu_visible: false,
      user_menu_visible: false,
      user_dropdown_visible: false,
      items_nav_primary: [],
      items_nav_primary_section_light: [
        {
          type: 'text',
          text: 'over', 
          to: '/over'
        },
        {
          type: 'text',
          text: 'vragen?', 
          to: '/vragen'
        },
        {
          type: 'text',
          text: 'contact', 
          to: '/contact'
        },
      ],
      items_nav_primary_section_dark: [
        {
          type: 'text',
          text: 'overzicht', 
          to: '/cursussen'
        },
        {
          type: 'text',
          text: 'mijn takeaways', 
          to: '/takeaways'
        }
        /*
        */
      ],
      items_nav_secondary: [
        {
          type: 'text',
          text: 'registreer', 
          to: '/registreren'
        },
        {
          type: 'block',
          //behave: 'block',
          text: 'log in', 
          to: '/login'
        },
      ],
      items_nav_dropdown: [],
      items_nav_dropdown_default: [
        {
          text: 'overzicht', 
          to: '/cursussen'
        },
        {
          type: 'text',
          text: 'mijn takeaways', 
          to: '/takeaways'
        },
        /*
        */
        {
          text: 'mijn profiel', 
          to: '/mijn-profiel'
        },
        {
          text: 'uitloggen', 
          to: '/logout'
        },
      ],
      items_nav_dropdown_mobile: [],
      items_nav_dropdown_mobile_light: [
        {
          text: 'overzicht', 
          to: '/cursussen'
        },
        {
          type: 'text',
          text: 'mijn takeaways', 
          to: '/takeaways'
        },
        /*
        */
        {
          text: 'mijn profiel', 
          to: '/mijn-profiel'
        },
        {
          text: 'uitloggen', 
          to: '/logout'
        },
      ],
      items_nav_dropdown_mobile_dark: [
        {
          text: 'mijn profiel', 
          to: '/mijn-profiel'
        },
        {
          text: 'uitloggen', 
          to: '/logout'
        },
      ],
    }
  },
  mounted() {
    lv.thisVue = this
    lv.thisVue.items_nav_dropdown = lv.thisVue.items_nav_dropdown_default
    lv.thisVue.items_nav_primary = lv.thisVue.items_nav_primary_section_light
    lv.thisVue.items_nav_dropdown_mobile = lv.thisVue.items_nav_dropdown_mobile_light


    this.$nextTick(function () {
      if(globals.dark_section){
        lv.thisVue.items_nav_primary = lv.thisVue.items_nav_primary_section_dark
        lv.thisVue.items_nav_dropdown_mobile = lv.thisVue.items_nav_dropdown_mobile_dark
        lv.thisVue.dark_mode = true
      }
    })
    /*
    setTimeout(function(){
      if(globals.dark_section){
        lv.thisVue.items_nav_primary = lv.thisVue.items_nav_primary_section_dark
      }
    },100)
    */

    EventBus.$on('site_section_dark', (e) => {
      lv.thisVue.items_nav_primary = lv.thisVue.items_nav_primary_section_dark
      lv.thisVue.items_nav_dropdown_mobile = lv.thisVue.items_nav_dropdown_mobile_dark
      lv.thisVue.dark_mode = true
    })
    EventBus.$on('site_section_light', (e) => {
      lv.thisVue.items_nav_primary = lv.thisVue.items_nav_primary_section_light
      lv.thisVue.items_nav_dropdown_mobile = lv.thisVue.items_nav_dropdown_mobile_light
      lv.thisVue.dark_mode = false
    })
    logged_in_home()
  },
  methods: {
    mobile_menu_toggle() {
      if(this.mobile_menu_visible){
        this.mobile_menu_visible = false
      }else{
        lv.thisVue.items_nav_dropdown = lv.thisVue.items_nav_dropdown_mobile
        this.mobile_menu_visible = true
      }
    },
    user_dropdown_toggle() {
      if(this.user_dropdown_visible){
        this.user_dropdown_visible = false
      }else{
        lv.thisVue.items_nav_dropdown = lv.thisVue.items_nav_dropdown_default
        this.user_dropdown_visible = true
      }
    },
    user_dropdown_blur() {
      this.user_dropdown_visible = false
    }
  },
  watch:{
    $route (to, from){
      this.mobile_menu_visible = false
    }
  }  
}


function logged_in_home(){
  if(globals.logged_in){
    lv.thisVue.logged_in = true
    lv.thisVue.user_menu_visible = true
    lv.thisVue.user_name = globals.user_data.first_name + ' ' + globals.user_data.last_name
    lv.thisVue.avatar_src = globals.user_data.avatar
  }else{
    logged_out_home()
  }
}
function logged_out_home(){
  lv.thisVue.logged_in = false
  lv.thisVue.user_menu_visible = false
  lv.thisVue.user_name = ''
  lv.thisVue.avatar_src = null
}
EventBus.$on('login_check_success', (e) => {
  logged_in_home()
})
EventBus.$on('logged_out', (e) => {
  logged_out_home()
})
EventBus.$on('avatar_uploaded', (e) => {
  if(e.result == 'ok'){
    lv.thisVue.avatar_src = e.avatar
  }
})

</script>

<style lang="scss" scoped>

  .nav-height{
    height: 117px;
  }
  .nav-text{
    font-size: 21px;
    font-weight: 700;
    padding-left: 18px;
    padding-right: 19px;
  }
  .nav-btns-wrap{
    // padding: 10px 10px 15px 67px;
    padding-left: 51px;
  }
  .nav-btns-fix{
    padding-top: 13px;
  }
  .nav-active{
    text-transform: capitalize;
  }
  .nav-btn-round{
    padding-left: 38px;
    padding-right: 38px;
    border-radius: 5px;
    letter-spacing: 1px;
  }
  .nav-right{
    padding-bottom: 5px;
  }
  .navtrans{
    transition: background-color 200ms linear;
  }


</style>
