<template>
  <div class="home" >

    <div class="mode-center relative">

      <!--  
      <div class="pos-g bg-no-repeat bg-right-bottom mxxxl:hidden" :style="{ 'background-image': 'url(' + homeData.image + ')' }"></div>
      -->

      <div class="relative">
        <div class="vspace_top_page"></div>
        <div class="grid grid-cols-2 gap-4 mxxxl:grid-cols-1">
          <div class="">
            <div class="text-hdr-1">
              {{homeData.title}}
            </div>
            <div class="text-subhdr-1 font-mono mt-3 pr-3">
              {{homeData.subtitle}}
            </div>
            <!--  
            <div class="mt-10 ml-1">
              <img :src="homeData.image" />
            </div>
            -->
            <div class="mt-6 text-subhdr-3" v-html="homeData.text">
            </div>
          </div>
          <div class="text-right ">
            <img class="inline-block" :src="homeData.image" />
          </div>
        </div>
        <!--  
        <div class="mnxxl:hidden text-right ">
          <img class="inline-block" :src="homeData.image" />
        </div>
        -->
        <div class="vspace_middle_page"></div>
      </div>

      
    </div>


    <div class="bg-white">
      <div class="mode-center-big">
        <div class="mode-center">
          <div class="pt-12 pb-20">
            <div class="text-hdr-1">
              Onze cursussen
            </div>
            <div class="text-subhdr-1 font-mono mt-5 pb-12">
              Deze cursussen zijn misschien ook interessant voor jou
            </div>

            <div class="a">

              <course_card  
                v-for="item in items_courses" 
                :key="item.slug" 
                :color="item.color_course"
                :title="item.title" 
                :teaser_text="item.teaser_text" 
                :description="item.description" 
                :text="item.text" 
                :id="item.id" 
                :slug="item.slug" 
                :img="item.img" 
                :to="item.to"
                :percentage="item.percentage"
                :time_string="item.time_string"
                :lesson_amount="item.lesson_amount"
                :public="true"
              />

            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="mode-center">
      <div class="pt-20 pb-12">
        <div class="text-hdr-1">
          {{homeData.title_section_2}}
        </div>
      </div>

      <div v-for="(item, index) in items_grid1" :key="index" 
        class="grid grid-cols-2 gap-5 gap-y-9 mxmd:grid-cols-1 pb-12" 
        :class="{ 'mb-20' : index % 2 === 1}"
      >
        <div :class="{ 'text-right' : index % 2 === 1}" v-if="item.img" >
          <img :src="item.img" class="inline-block" />
        </div>
        <div :class="{ 'mnmd:order-first' : index % 2 === 1, 'col-span-2' : !item.img}">
          <div class="text-hdr-3 pad-col-page-1">
            {{item.title}}
          </div>
          <div class="text-subhdr-3 text-col-page-1" v-html="item.text">
          </div>
        </div>
      </div>


    </div>

    <div class="pb-4"></div>
    
    <hero_cta :cta_data="cta_data" v-if="show_cta" />

  </div>
</template>

<script>
import { EventBus } from '@/modules/event_bus'
import { globals } from '@/modules/globals'
import api from '@/modules/api'

import course_card from '@/components/course_card.vue'
import hero_cta from '@/components/ui/hero_cta.vue'

var lv = {}
lv.thisVue = null

export default {
  name: 'Home',
  components: {
    course_card,
    hero_cta,
  },
  data() {
    return {
      homeData:{},
      /*
      homeData:{
        image: '@assets/img/placeholder.png'
      },
      */
      show_cta: false,
      cta_data:{
        text: 'Hier een call-to-action',
        button: {
          link: '/registreren',
          text: 'Registreren'
        }
      },
      items_courses: [],
      items_grid1:[]
    }
  },
  mounted() {

    lv.thisVue = this

    lv.dataTask = "home_fetch"
    lv.dataEvent = "data_home_detail"
    api.call({
      task: lv.dataTask,
      global: true,
      event: lv.dataEvent,
    })
    EventBus.$on(lv.dataEvent, (e) => {
      dataLoaded(e)
    })

    function dataLoaded(d) {

      lv.home_data = globals.api_data[lv.dataTask].item

      lv.home_data_prep = {}
      lv.home_data_prep.title = lv.home_data.title
      lv.home_data_prep.subtitle = lv.home_data.subtitle
      lv.home_data_prep.text = lv.home_data.text
      lv.home_data_prep.image = globals.api_data[lv.dataTask].resources[globals.api_data[lv.dataTask].item.image].file
      lv.home_data_prep.title_section_2 = lv.home_data.title_section_2
      lv.home_data_prep.page_parts_section_2 = lv.home_data.page_parts_section_2

      lv.items_section_2 = []
      lv.s2_total = Object.keys(lv.home_data_prep.page_parts_section_2).length
      lv.nr = 0
      while(lv.nr < lv.s2_total){
        lv.tmp = lv.home_data_prep.page_parts_section_2[lv.nr]
        lv.img = null
        if(globals.api_data[lv.dataTask].resources[lv.tmp.image]){
          lv.img = globals.api_data[lv.dataTask].resources[lv.tmp.image].file
        }
        lv.items_section_2.push({
          title: lv.tmp.title,
          text: lv.tmp.text,
          img: lv.img,
        })
        lv.nr++
      }
      lv.thisVue.items_grid1 = lv.items_section_2
      lv.cta_data = JSON.parse(lv.home_data.call_to_action)
      if(lv.cta_data && lv.cta_data != '' && lv.cta_data != '{}'){
        lv.thisVue.show_cta = true
        lv.thisVue.cta_data = lv.cta_data
      }

      lv.thisVue.homeData = lv.home_data_prep
    }



    lv.dataTask_courses = 'courses_fetch_public'
    lv.dataEvent = 'data_courses_fetch_public'
    api.call({
      task: lv.dataTask_courses,
      view: 'public',
      global: true,
      event: lv.dataEvent
    })
    EventBus.$on(lv.dataEvent, (e) => {
      dataLoaded_courses(e)
    })

    function dataLoaded_courses(d){
      if (d.result != 'ok') {
        //console.log('----- data load fail -----')
      }
      if (d.result == 'ok') {
        //console.log('----- data load success -----')

        lv.transpose = []
        for (let key in globals.api_data[lv.dataTask_courses].items) {
          lv.item = []
          lv.item.id = globals.api_data[lv.dataTask_courses].items[key].id
          lv.item.title = globals.api_data[lv.dataTask_courses].items[key].title
          lv.item.slug = globals.api_data[lv.dataTask_courses].items[key].slug
          lv.item.teaser_text = globals.api_data[lv.dataTask_courses].items[key].teaser_text
          lv.item.description = globals.api_data[lv.dataTask_courses].items[key].description
          lv.item.text = globals.api_data[lv.dataTask_courses].items[key].text
          //lv.item.img = globals.api_data[lv.dataTask_courses].resources[globals.api_data[lv.dataTask_courses].items[key].header_image].medium
          lv.item.img = globals.api_data[lv.dataTask_courses].resources[globals.api_data[lv.dataTask_courses].items[key].featured_image].file
          lv.item.to = '/cursussen/' + lv.item.slug
          lv.item.percentage = globals.api_data[lv.dataTask_courses].items[key].percentage
          lv.item.color_course = globals.api_data[lv.dataTask_courses].items[key].color
          if(!lv.item.color_course){
            lv.item.color_course = '#00d45b'
          }
          lv.item.lesson_amount = globals.api_data[lv.dataTask_courses].items[key].lesson_count

          lv.item.time_string = globals.api_data[lv.dataTask_courses].items[key].hours_to_study_total + ' uur'
          lv.transpose.push(lv.item)
        }
        lv.thisVue.items_courses = lv.transpose
      }
    }




  },
  methods: {
  }
}
</script>

<style>


 .brdr-tmp{
   border:1px dashed #dad8d8;
 }

.tmp-v-space{
  height: 42px;
}


</style>