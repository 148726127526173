<template>

  <div class="progress_bar_wrap rounded-progress overflow-hidden w-full relative" v-bind:class="{'bg-white': bg_white, 'bg-gray-300': bg_gray}">
    <div class="bg-kiwi progress_indicate h-full" v-bind:style="{ 'width': percentage + '%' }" ></div>
    <div class="absolute progress_text_pos_left text-micro-1 uppercase">
        {{progress_text}}
    </div>
    <div class="absolute progress_text_pos_right text-micro-1 uppercase">
      <span>{{percentage}}</span><span>%</span>
    </div>
  </div>

</template>

<script>

var lv = {}
lv.thisVue = null

export default {
  name: 'progress_bar',
  components: {
  },
  props: ['percentage','progress_text','bg'],
  data() {
    return {
      bg_white: true,
      bg_gray: false
    }
  },
  mounted() {
    if(this.$props.bg == 'gray'){
      this.bg_white = false
      this.bg_gray = true
    }
  },
  methods: {
  }
}

</script>

<style lang="scss" scoped>

.progress_bar_wrap{
  height: 20px;
}
.progress_indicate{
  width: 0%;
}
.progress_text_pos_left{
  left:10px;
  top:3px;
}
.progress_text_pos_right{
  right:10px;
  top:3px;
}
.rounded-progress{
  border-radius: 3px;
}

</style>
