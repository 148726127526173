
export var globals = {
    host: 'http://localhost/',
    /*
    host: 'https://dlp.multitudestaging.nl/',
    host: 'https://multitude.laylohosting.nl/',
    host: 'https://diversionacademy.nl/',
    */
    path_main: 'uploads/learning-platform/originals/',
    path_avatars: 'uploads/learning-platform/avatars/',
    cookie_allowed: false,
    preview_allowed: true,
    api_data:{},
    connection_established: false,
    consolify: false,
    logged_in: false,
    admin_user: null,
    user_data:{
        id: 0,
        first_name: '',
        last_name: '',
        avatar: null
    },
    account_is_removed: false,
    dark_section: false,
    scrollSaved: {x:0, y:0},
    bookmark_float_visible: false,
    nav_from_takeaways: 0
}

if(process.env.NODE_ENV === 'production'){
    globals.host = 'https://diversionacademy.nl/'
}
/*
*/

export default globals