<template>

  <router-link
  :to="to" 
  v-bind:class="classes"
  >
    {{text}}
  </router-link>

</template>


<script>


export default {
  name: 'btn_nav',
  props: ['type','text','to'],
  data() {
    return {
      classes: 'bg-blue-sky hover:bg-blue-sky-600 text-white font-bold font-mono uppercase rounded btn-page transition duration-300 ease-in-out'
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>

.btn-page{
  font-size: 20px;
  letter-spacing: 1.6px;
  padding: 10px 90px 10px 90px;
}

@media (max-width: 1080px){
  .btn-page{
    font-size: 18px;
    letter-spacing: 1.6px;
    padding: 10px 30px 10px 30px;
  }
 }

</style>
