<template>

  <div class="pb-8">
    <div class="grid grid-cols-2 gap-0 mxmd:grid-cols-1 rounded-lg overflow-hidden">
      <div class="coursecard_padding coursecard_height" v-bind:style="{ backgroundColor: color }">
        <div class="relative h-full flex flex-col">
          <div class="text-hdr-3">
            {{title}}
          </div>
          <div class="text-subhdr-3 mt-2 pr-7 mb-4 coursecard_text" v-html="teaser_text">
          </div>
          <div class="mt-auto">
            <div class="inline-block align-top coursecard_marginright">
              <div class="inline-block align-top pb-4">
                <div class="coursecard_iconwrap">
                  <!--  
                  <div class="rounded-full coursecard_icon_div bg-black opacity-10"></div>
                  <svg class="icon_svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46"><defs></defs><g class="cls-1"><g id="Layer_1" data-name="Layer 1"><circle class="cls-2" cx="141.73" cy="141.73" r="115.63"/><rect class="cls-3" x="34.65" y="69.36" width="107.08" height="144.75"/><rect class="cls-3" x="141.73" y="69.36" width="107.08" height="144.75"/><rect class="cls-4" x="155.68" y="83.19" width="79.2" height="7.12"/><rect class="cls-4" x="155.68" y="97.78" width="79.2" height="7.12"/><rect class="cls-4" x="155.68" y="112.38" width="79.2" height="7.12"/><rect class="cls-4" x="155.68" y="126.97" width="79.2" height="7.12"/><rect class="cls-4" x="155.68" y="141.57" width="35.23" height="7.12"/><rect class="cls-4" x="48.59" y="83.19" width="79.2" height="7.12"/><rect class="cls-4" x="48.59" y="97.78" width="79.2" height="7.12"/><rect class="cls-4" x="48.59" y="112.38" width="79.2" height="7.12"/><rect class="cls-4" x="48.59" y="126.97" width="79.2" height="7.12"/><rect class="cls-4" x="48.59" y="141.57" width="79.2" height="7.12"/><rect class="cls-4" x="48.59" y="156.17" width="79.2" height="7.12"/><rect class="cls-4" x="48.59" y="170.76" width="79.2" height="7.12"/><rect class="cls-4" x="48.59" y="185.36" width="79.2" height="7.12"/></g></g></svg>
                  -->

                  <img class="icon_80" src="@/assets/img/icons_graphic/icon_lesson-amount.png" />

                </div>
              </div>
              <div class="inline-block align-top">
                <div class="text-body-1">
                  aantal lessen
                </div>
                <div class="text-mini-1">
                  {{lesson_amount}}
                </div>
              </div>
            </div>
            <div class="inline-block align-top">
              <div class="inline-block align-top">
                <div class="coursecard_iconwrap">
                  <!--  
                  <div class="rounded-full coursecard_icon_div bg-black opacity-10"></div>
                  <svg class="icon_80" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46"><defs></defs><g class="cls-1"><g id="Layer_1" data-name="Layer 1"><circle class="cls-2" cx="141.73" cy="141.73" r="115.63"/><rect class="cls-3" x="134.75" y="57.38" width="13.96" height="13.96"/><rect class="cls-3" x="172.61" y="62.77" width="13.96" height="26.8" transform="translate(62.14 -79.59) rotate(30)"/><rect class="cls-3" x="122.32" y="50.15" width="38.83" height="7.23"/><circle class="cls-4" cx="141.73" cy="141.73" r="78.79"/><polyline class="cls-4" points="141.73 97.7 141.73 141.73 180.56 180.56"/><line class="cls-4" x1="141.73" y1="72.45" x2="141.73" y2="91.76"/><line class="cls-4" x1="141.73" y1="191.71" x2="141.73" y2="211.02"/><line class="cls-4" x1="72.45" y1="141.73" x2="91.76" y2="141.73"/><line class="cls-4" x1="191.71" y1="141.73" x2="211.02" y2="141.73"/><line class="cls-4" x1="107.09" y1="81.73" x2="116.75" y2="98.45"/><line class="cls-4" x1="166.72" y1="185.01" x2="176.38" y2="201.74"/><line class="cls-4" x1="81.73" y1="176.38" x2="98.45" y2="166.72"/><line class="cls-4" x1="185.01" y1="116.75" x2="201.74" y2="107.09"/><line class="cls-4" x1="81.73" y1="107.09" x2="98.45" y2="116.75"/><line class="cls-4" x1="185.01" y1="166.72" x2="201.74" y2="176.38"/><line class="cls-4" x1="107.09" y1="201.74" x2="116.75" y2="185.01"/><line class="cls-4" x1="166.72" y1="98.45" x2="176.38" y2="81.73"/><circle class="cls-3" cx="141.73" cy="141.73" r="5.68"/></g></g></svg>
                  -->

                  <img class="icon_80" src="@/assets/img/icons_graphic/icon_study-time.png" />

                </div>
              </div>
              <div class="inline-block align-top">
                <div class="text-body-1">
                  duur cursus
                </div>
                <div class="text-mini-1">
                  {{time_string}}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="bg-cover bg-center coursecard_height_img" v-bind:style="{ 'background-image': 'url(' + img + ')' }" >
        <div class="relative h-full coursecard_padding_img">
          <progress_bar progress_text="voortgang" :percentage="percentage" v-if="show_non_public" />
          <div class="absolute card_button_pos">
            <btn_default :text="btn_text" :to="to" />
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import progress_bar from '@/components/misc/progress_bar.vue'
import btn_default from '@/components/ui/btn_default.vue'

export default {
  name: "course_card",
  components: {
    progress_bar,
    btn_default
  },
  props: ['title', 'teaser_text', 'description', 'text', 'id', 'slug', 'img', 'to', 'color', 'percentage','time_string','lesson_amount','public'],
  data() {
    return {
      btn_text_done: 'bekijk',
      btn_text_resume: 'ga verder',
      btn_text_start: 'start',
      btn_text: 'ga verder',
      show_non_public: true
    }
  },
  mounted() {

    if(this.$props.public){
      this.show_non_public = false
      this.btn_text = this.btn_text_start
    }

    if(this.$props.percentage === 100){
      this.btn_text = this.btn_text_done
    }else if(this.$props.percentage > 0){
      this.btn_text = this.btn_text_resume
    }else{
      this.btn_text = this.btn_text_start
    }

  }
}
</script>

<style scoped lang="scss">

.coursecard_padding{
  padding: 25px 15px 15px 36px;
}

.coursecard_height{
  min-height:430px;
}
.coursecard_height_img{
  min-height:430px;
}
.coursecard_iconwrap{
  width: 85px;
  height: 60px;
}
.coursecard_marginright{
  margin-right: 60px;
}
.coursecard_padding_img{
  padding: 32px 36px 32px 44px;
}
.card_button_pos{
  bottom: 30px;
  right: 36px;
}
.coursecard_text{
  max-height: 124px;
  overflow: hidden;
}
.coursecard_icon_div{
  width: 60px;
  height: 60px;
}

@media (max-width: 720px){
  .coursecard_height{
    min-height:290px;
  }
  .coursecard_height_img{
    height:290px;
    min-height: auto;
  }
  .coursecard_text{
    max-height: 130px;
  }
  .coursecard_marginright{
    margin-right: 20px;
  }
}

</style>
