<template>

<!--  
  @click.prevent="$emit('btn-click')"
-->
  <div class="inline-block w100">
    <button
      class="bg-blue-sky hover:bg-blue-sky-600 text-white btn-default font-mono py-3 px-6 rounded-5px focus:outline-none focus:bg-blue-sky-700 relative"
      v-bind:class="classes"
      @click="$emit('btn-click')"
      v-if="button_mode == true"
    >
      <div class="inline-block px-8">
        {{text}}
      </div>
      <div class="btn-icon" v-show="state === 'processing'">
        <svg class="animate-spin h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      </div>
    </button>

    <div class="relative">
      <button
        class="bg-blue-sky hover:bg-blue-sky-600 text-white btn-default font-mono py-2 px-8 w100 maxwbtn rounded-5px focus:outline-none focus:bg-blue-sky-700 relative mnmd:whitespace-no-wrap"
        v-bind:class="classes"
        v-bind:style="style"
        @click="$emit('btn-click')"
        v-if="button_mode == 'wide'"
      >
        <div class="inline-block px-8">
          {{text}}
        </div>
        <div class="btn-icon" v-show="state === 'processing'">
          <svg class="animate-spin h-5 w-5 fill-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      </button>
      <div class="pos-g" v-show="state === 'disabled'">
      </div>
    </div>

    <router-link
      class="bg-blue-sky hover:bg-blue-sky-600 text-white btn-default font-mono py-3 px-6 rounded-5px focus:outline-none focus:bg-blue-sky-700 relative block text-center"
      v-bind:class="classes"
      :to="to"
      v-if="button_mode == false"
    >
      <div class="inline-block px-4">
        {{text}}
      </div>
    </router-link>

  </div>


</template>


<script>


export default {
  name: 'btn_nav',
  props: ['type','text','to','state'],
  data() {
    return {
      classes: null,
      style: null,
      button_mode: true,
      watch_state: null
    }
  },
  mounted() {
    if(this.$props.type == 'w100'){
      this.classes = 'w100'
    }
    if(this.$props.type == 'wide'){
      this.button_mode = 'wide'
    }
    if(this.$props.to){
      this.button_mode = false
    }
    if(this.$props.state == 'disabled'){
      this.watch_state = 'disabled'
      this.style = 'opacity:0.5;'
    }
  },
  watch: { 
    state: function(newVal, oldVal) {
      if(newVal != 'disabled'){
        this.style = ''
      }else{
        this.style = 'opacity:0.5;'
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.btn-default{
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 1.25rem;
    text-transform: uppercase;
}
.btn-icon{
  position:absolute; top:50%; right:10%;
  transform: translate(0,-50%); 
}
.maxwbtn{
  max-width: 448px;
}
.maxwbtn_block{
  max-width: 448px;
}
@media (max-width: 720px){
  .maxwbtn{
    max-width: none;
  }
  
}

</style>
