<template>

<nav class="bg-blue-sky footer-height absolute w100 bottom-0" id="footer_main">
  <div class="mode-center">
    <div class="footer-img mnxxsm:flex items-center pt-12">
      <div class="mr-16 footicon flex-shrink-0">
        <img src="@/assets/img/logo-footer-sm.png" />
      </div>
      <div class="footnav text-subhdr-3 text-white flex flex-wrap mnmd:flex-col mnmd:h-24 mxmd:w-64">
        <div class="mr-16 pb-2" v-for="(item, index) in data" :key="index">
          <router-link :to="'/page/' + item.slug" class="hover:text-blue-sky-900 lowercase transition duration-200 ease-in-out">
          {{item.title}}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</nav>

</template>


<script>
import { globals } from '@/modules/globals'

var lv = {}
lv.thisVue = null

export default {
  name: 'footer_default',
  components:{
  },
  props: [],
  data() {
    return {
      data: null
    }
  },
  mounted() {

    lv.thisVue = this
    this.data = globals.api_data['textpages_fetch'].items

  },
  methods: {
  }}
</script>

<style lang="scss" scoped>

  .footer-height{
    min-height: 191px;
  }
  .footnav{
    position: relative;
    top: 3px;
  }
  .footicon{
    margin-right: 51px;
  }
  @media (max-width: 480px){
    .footicon{
      margin-right: 30px;
    }
    .footnav{
      padding-top:40px;
      padding-bottom: 40px;
      top: 0px;
    }
  }


</style>
