import Vue from 'vue'
import VueRouter from 'vue-router'
import globals from '@/modules/globals'
import { EventBus } from '@/modules/event_bus'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/over',
    name: 'About',
    component: () => import( /* webpackChunkName: "main" */ '../views/About.vue')
  },
  {
    path: '/vragen',
    name: 'F.A.Q.',
    component: () => import( /* webpackChunkName: "main" */ '../views/Faq.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import( /* webpackChunkName: "main" */ '../views/Contact.vue')
  },
  {
    path: '/registreren',
    alias: '/register',
    name: 'Register',
    component: () => import( /* webpackChunkName: "profile" */ '../views/account/Register.vue')
  },
  {
    path: '/verificatie/:salt',
    name: 'Registration_verify',
    component: () => import( /* webpackChunkName: "profile" */ '../views/account/Registration_verify.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "profile" */ '../views/account/Login.vue')
  },
  {
    path: '/wachtwoord-vergeten',
    name: 'Password_forgot',
    component: () => import( /* webpackChunkName: "profile" */ '../views/account/Password_forgot.vue')
  },
  {
    path: '/wachtwoord-herstellen/:salt',
    name: 'Password_reset',
    component: () => import( /* webpackChunkName: "profile" */ '../views/account/Password_reset.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import( /* webpackChunkName: "profile" */ '../views/account/Logout.vue')
  },
  {
    path: '/mijn-profiel',
    name: 'Profile',
    component: () => import( /* webpackChunkName: "profile" */ '../views/account/Profile.vue')
  },
  {
    path: '/account-verwijderd',
    name: 'AccountRemoved',
    component: () => import( /* webpackChunkName: "profile" */ '../views/account/AccountRemoved.vue')
  },
  {
    path: '/cursussen',
    name: 'Courses',
    component: () => import( /* webpackChunkName: "tool" */ '../views/Courses.vue')
  },
  {
    path: '/cursussen/:cursus',
    name: 'Course',
    component: () => import( /* webpackChunkName: "tool" */ '../views/Course.vue'),
  },
  {
    path: '/cursussen/:cursus/:lesson',
    name: 'Lesson',
    component: () => import( /* webpackChunkName: "tool" */ '../views/Lesson.vue')
  },
  {
    path: '/preview',
    name: 'Preview',
    component: () => import( /* webpackChunkName: "tool" */ '../views/Preview.vue')
  },
  {
    path: '/preview/cursussen',
    name: 'PreviewCourses',
    component: () => import( /* webpackChunkName: "tool" */ '../views/Courses.vue')
  },
  {
    path: '/preview/cursussen/:cursus',
    name: 'PreviewCourse',
    component: () => import( /* webpackChunkName: "tool" */ '../views/Course.vue')
  },
  {
    path: '/preview/cursussen/:cursus/:lesson',
    name: 'PreviewLesson',
    component: () => import( /* webpackChunkName: "tool" */ '../views/Lesson.vue')
  },
  {
    path: '/page/:textpage',
    name: 'TextPage',
    component: () => import( /* webpackChunkName: "main" */ '../views/TextPage.vue')
  },
  {
    path: '/takeaways',
    name: 'Takeaways',
    component: () => import( /* webpackChunkName: "tool" */ '../views/Takeaways.vue')
  },
  {
    path: '*',
    name: 'Page_404',
    component: () => import( /* webpackChunkName: "main" */ '../components/page_404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  /*
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      //globals.scrollSaved = savedPosition
      return savedPosition;
    } else if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 };
    }
  }
  */
})


const routes_need_auth = ['Profile','Courses','Course','Lesson','Takeaways']
const routes_canthave_auth = ['Login','Register']

router.beforeEach((to, from, next) => {
  if(routes_need_auth.includes(to.name) && !globals.logged_in){
    // next({ name: 'Login' })
    next({ name: 'Register' })
  }else if(routes_canthave_auth.includes(to.name) && globals.logged_in){
    next({ name: 'Courses' })
  }else{
    next()
  }
})

/*
var body_node = document.getElementsByTagName('BODY')[0]
const routes_have_dark = ['Profile','Courses','Course','Lesson']
router.afterEach((to, from) => {
  console.log('after', to, from)
  if(routes_have_dark.includes(to.name) && !globals.dark_section){
    globals.dark_section = true
    body_node.classList.add('dark')
    EventBus.$emit('site_section_dark')
  }
  if(!routes_have_dark.includes(to.name) && globals.dark_section){
    globals.dark_section = false
    body_node.classList.remove('dark')
    EventBus.$emit('site_section_light')
  }
})
*/

export default router
